import React, { Component } from 'react';
import Home from '../components/Home';
import Footer from '../components/Footer';
import i18n from '../i18n';
import myTitle from './../assets/title.png';
import { Helmet } from 'react-helmet-async';

class HomePage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="home">
                <Helmet> 
                    <meta lang={i18n.language.substr(0,2)} /> 
                    <title>{i18n.t('meta site title')} {i18n.t('meta site title homepage')}</title>
                </Helmet>
                <div id="myLoader" className="loader">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <div className="wrapper" id="myBanner">
                    <div className="tint" id="myTint">
                        <div className="myTitle"><img src={myTitle} alt="Long Tall Jefferson" /></div>   
                    </div>
                </div>
                <div className="socials"></div>
                <div className="buttonMainPage"><a href="https://www.longtalljefferson.com">Take me to the main page</a></div>
                
                <div className="wrapperSqueeze">
                    <Home />
                </div>
                <Footer />
            </div>
        );
    }
}

export default HomePage;