import React, { Component } from 'react';
import Loading from './Loading';
import AttachedAlbums from './AttachedAlbums';
import AttachedLinks from './AttachedLinks';
import AttachedVideos from './AttachedVideos';
import Bandsintown from './Bandsintown';
import Newsletter from './Newsletter';
import textListenTo from './../assets/text-listen-to.png';
import textPhotos from './../assets/text-photos.png';
import textVideos from './../assets/text-videos.png';
import textUpcomingShows from './../assets/text-upcoming-shows.png';

import LightboxGallery from './LightboxGallery';
//import i18n from '../i18n';

const API = 'https://admin.longtalljefferson.com/wp-json/wp/v2/';
const QUERY = 'one_pager/295/?_embed&filter[orderby]=menu_order&order=asc';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let content = this.state.dataSet.content.rendered;
        let albumEmbed = this.state.dataSet.acf.album_embed;
        let attachedImages = this.state.dataSet.acf_photo_gallery;
        let attachedImagesArray = this.state.dataSet.acf_photo_gallery.large_srcset;
        let attachedAlbums = this.state.dataSet.acf_photo_gallery_2;
        let attachedLinks = this.state.dataSet.acf_photo_gallery_3;
        let attachedVideos = this.state.dataSet.acf_photo_gallery_4;
        let rbc = this.state.dataSet.rbc;
        let contact = this.state.dataSet.acf.contact;
        return (
            <div>
                <div className="landingPage">
                    <div className="blockFull">
                       <div className="blockText">
                            <div className="about padLow" dangerouslySetInnerHTML={{__html: content}}></div>
                            <div className="anchor"><img src={textListenTo} className="title long" alt="Listen to Long Tall Jefferson"/></div>
                            <AttachedLinks data={attachedLinks} />
                            <div className="anchor"><img src={textVideos} className="title" alt="Videos"/></div>
                            
                            <AttachedVideos data={attachedVideos} />
                            
                           
                            <div className="anchor"><img src={textPhotos} className="title" alt="Photos"/></div>
                            <LightboxGallery data={attachedImages} array={attachedImagesArray} />

                            <div className="anchor"><img src={textUpcomingShows} className="title" alt="Upcoming Shows"/></div>
                            <Bandsintown />

                            {/*<div className="album padLow" dangerouslySetInnerHTML={{__html: albumEmbed}}></div>
                            <div className="anchor">Listen to Long Tall Jefferson</div>
                            <AttachedLinks data={attachedLinks} />
                            <div className="anchor">Music</div>
                            <AttachedAlbums data={attachedAlbums} />
                            */}
                            
                            {/*
                            <div className="anchor">Newsletter</div>
                            <Newsletter />
                            <div className="anchor">Contact</div>
                            <div className="contact padLow" dangerouslySetInnerHTML={{__html: contact}}></div>
                            <div className="rbc padLow" dangerouslySetInnerHTML={{__html: rbc}}></div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;