import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Img from './Img';
 
class LightboxGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 1,
      data: this.props.data,
      isOpen: false
    };
  }

 
  render() {
    const { photoIndex, isOpen } = this.state;
    var images = [];
    let imagesList = this.state.data.map((image, index) => {
      let imageSet = image.medium_srcset.split(" ");
      let imageSrc = imageSet[4];

      let imageSetLarge = image.large_srcset.split(" ");
      let imageSrcLarge = imageSetLarge[6]; //image resolution picked
        if (imageSrcLarge === undefined) {
          imageSrcLarge = imageSetLarge[4]; //image resolution picked
            if (imageSrcLarge === undefined) {
              imageSrcLarge = imageSetLarge[2]; //image resolution picked
             }
        }
      
      images.push(imageSrcLarge);
      let imageTitle = image.title;
      //let url = image.full_image_url;
      return (
          <div  className="attachedImage" key={index}>
              <Img src={imageSrc} onClick={() => this.setState({ isOpen: true, photoIndex: index })} className="load" alt={imageTitle} />
          </div>
      )
     });
 
    return (
      <div>
        <div className="attachedImages">
            { imagesList }
        </div>
 
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default LightboxGallery;