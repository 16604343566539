import React from 'react'

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValue: '',
        fNameValue: '',
        cityValue: '',
        countryValue: '',
    };
  }

    render() {
        return (
                <div className="newsletter">
                <p>If you’d like be updated about new releases and get a notification when I come to your town, please sign in for my newsletter:</p>
                <form action="https://longtalljefferson.us16.list-manage.com/subscribe/post?u=7012a09ab11a1c873c1dfcb45&id=66d50a57ea" method="POST" noValidate>
                  <input type="hidden" name="u" value="eb05e4f830c2a04be30171b01"/>
                <input type="hidden" name="id" value="8281a64779"/>
                <label htmlFor='MERGE0'>
                    <input 
                        type="email" 
                        name="EMAIL" 
                        id="MERGE0"
                        value={this.state.emailValue} 
                        onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
                        autoCapitalize="off" 
                        autoCorrect="off"
                        placeholder="Email"
                     /> 
                </label>
                <label htmlFor='MERGE1'>
                    <input 
                        type="text" 
                        name="FNAME" 
                        id="MERGE1" 
                        value={this.state.fNameValue} 
                        onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                        placeholder="Name"
                    />
                </label>
                <label htmlFor='MERGE2'>
                    <input 
                        type="text" 
                        name="CITY" 
                        id="MERGE2" 
                        value={this.state.city} 
                        onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
                        placeholder="City"
                    />
                </label>
                <label htmlFor='MERGE3'>
                    <input 
                        type="text" 
                        name="COUNTRY" 
                        id="MERGE3" 
                        value={this.state.country} 
                        onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
                        placeholder="Country"
                    />
                </label>
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>

                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                    <label htmlFor="b_name">Name: </label>
                    <input type="text" name="b_name" tabIndex="-1" value="" placeholder="Name" id="b_name"/>

                    <label htmlFor="b_email">Email: </label>
                    <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email"/>

                    <label htmlFor="b_city">City: </label>
                    <input type="text" name="b_city" tabIndex="-1" value="" placeholder="City" id="b_city"/>

                    <label htmlFor="b_city">Country: </label>
                    <input type="text" name="b_country" tabIndex="-1" value="" placeholder="City" id="b_country"/>
                </div>
              </form>
              </div>
        )
    }
}

export default Newsletter